.notification-hover:hover {
    color: white;
}


.dropdown-menu {
    font-size: 12.5px;
    text-transform: uppercase;
    font-weight: 700;
}

.text-blue-dark {
    color: rgba(4, 15, 95, 1)

}