.w-300 {
    width: 21em;
}

.ml-20 {
    margin-left: 50px;
}

.w-150 {
    width: 150px;
}

.w-250 {
    width: 250px;
}
.pl-22 {
    padding-left: 6em;
}

.notif {
    margin-top: -2em;
    margin-left: 11em;
}

