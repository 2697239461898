.w-200 {
  width: 200px;
}
.lowercase {
  text-transform: lowercase;
}
.pt-1 {
  padding-top: 0.9em;
}
.holiday {
  color: white !important;
  background-color: #1976d2 !important;
  border-radius: 50px !important;
}

.holiday:hover {
  color: rgb(184, 234, 229) !important;
  background-color: #002d50 !important;
}
.holiday::after {
  content: "Holiday!";
  position: absolute;
  color: rgb(255, 251, 251);
  background-color: black;
  padding: 0.5em;
  border-radius: 10px;
  top: 2em;
  right: 0;
  display: none;
}

.holiday:hover::after {
  display: block;
}
