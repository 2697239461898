#marker_customer {
  background-image: url("./customer.png");
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

#marker_hotel {
  background-image: url("./hotel.png");
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.geocoder {
  position: absolute;
  z-index: 1;
  width: 50%;
  left: 50%;
  margin-left: -25%;
  top: 10px;
}
.mapboxgl-ctrl-geocoder {
  min-width: 100%;
}

.mapboxgl-ctrl-attrib-inner {
  display: none;
}
