@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.forget-password {
  color: indigo;
}

.forget-password:hover {
  color: blue;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}

.owl-dots .owl-dot span {
  background-color: red;
}
.select-custom-width {
  width: 100% !important;
}
.select-width-300 {
  width: 300px !important;
}

.select-width-200 {
  width: 200px !important;
}

.w-50 {
  width: 500px;
}
