h1 {
    font-family: "Roboto";
    text-align: center;
    margin-bottom: 40px;
  }
  
  .timer-wrapper {
    display: flex;
    justify-content: center;
  }
  
  .timer {
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .text {
    color: #aaa;
  }
  
  .value {
    font-size: 40px;
  }
  
  .info {
    max-width: 360px;
    margin: 40px auto 0;
    text-align: center;
    font-size: 16px;
  }
  .dialog {
    filter: blur(8px);
  -webkit-filter: blur(8px);
  }