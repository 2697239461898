.logo-lineData {
    position:absolute;
    right:7em;
    top: 4em;
    /* width: 200px; */
}
.footer-web {
    position: fixed;
    left: 0; bottom: 0;
     width: 100%
}