.block-events.e-schedule .template-wrap {
    width: 100%;
}

.block-events.e-schedule .e-vertical-view .e-resource-cells {
    height: 58px;
}

.block-events.e-schedule .e-timeline-view .e-resource-left-td,
.block-events.e-schedule .e-timeline-month-view .e-resource-left-td {
    width: 170px;
}

.block-events.e-schedule .e-resource-cells.e-child-node .employee-category,
.block-events.e-schedule .e-resource-cells.e-child-node .employee-name {
    padding: 5px
}

.block-events.e-schedule .employee-image {
    width: 45px;
    height: 40px;
    float: left;
    border-radius: 50%;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: 45px;
}

.block-events.e-schedule .employee-name {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.block-events.e-schedule .employee-designation {
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.block-events.e-schedule .template-wrap .employee-image.alice {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/alice.png');
}

.block-events.e-schedule .template-wrap .employee-image.robert {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/robert.png');
}

.block-events.e-schedule .template-wrap .employee-image.robson {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/robson.png');
}

.block-events.e-schedule .template-wrap .employee-image.laura {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/laura.png');
}

.block-events.e-schedule .template-wrap .employee-image.nancy {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/nancy.png');
}

.block-events.e-schedule .template-wrap .employee-image.margaret {
    background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/margaret.png');
}

.empImg {
    margin: 6px 10px;
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.name {
    margin-top: 4px;
}


.room-name {
    color: #083985;
    font-weight: bold;
}

.room-designation {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    color: #7b7c7d;
    margin-top: 4px;
}

/* .team-item {
    display: flex;
    flex-direction: row;
    
} */