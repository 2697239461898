.bg-profile{
	background-image: linear-gradient(rgba(0,0,0, 0.75), rgba(0,0,0, 0.75)), url('/src/assets/img/bg-company.jpg');
}


.wrg-toggle {
	touch-action: pan-x;
	display: inline-block;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	padding: 0;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
  }
  
  .wrg-toggle-input {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
  }
  
  .wrg-toggle-check, .wrg-toggle-uncheck {
	position: absolute;
	width: 10px;
	height: 10px;
	top: 0;
	bottom: 0;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	opacity: 0;
	transition: opacity .25s ease;
  }
  .wrg-toggle-check {
	left: 8px;
  }
  .wrg-toggle-uncheck {
	opacity: 1;
	right: 10px;
  }
  
  .wrg-toggle-uncheck span, 
  .wrg-toggle-check span {
	align-items: center;
	display: flex;
	height: 10px;
	justify-content: center;
	position: relative;
	width: 10px;
  }
  
  .wrg-toggle-container{
	width: 70px;
	height: 34px;
	padding: 0;
	border-radius: 30px;
	background-color: #898989;
	transition: all .2s ease;
  }
  
  .wrg-toggle-circle{
	transition: all .5s cubic-bezier(.23,1,.32,1) 0ms;
	position: absolute;
	top: 1px;
	left: 1px;
	width: 32px;
	height: 32px;
	border: 1px solid #898989;
	border-radius: 50%;
	background-color: #fafafa;
	box-sizing: border-box;
	transition: all .25s ease;
  }

  .wrg-toggle--checked .wrg-toggle-check{
	opacity: 1;
  }
  .wrg-toggle--checked .wrg-toggle-uncheck {
	opacity: 0;
  }
  .wrg-toggle--checked .wrg-toggle-circle {
	left: 37px;
  }
  .content_timeline {
	  display: flex;
	  
  }
  .footer-right {
	position: absolute;
	width: 7em;
 	right: 0;
  }