@tailwind base;
@tailwind components;
@tailwind utilities;

.carousel .rounded-xl span {
  background-color: #0d47a1;
}

/* .carousel .rounded-xl .plan {
  padding: 50px;
} */

.carousel .rounded-xl .bg-white\/50 {
  opacity: 0.3;
}
