.bg-login{
	background-image: linear-gradient(rgba(0,0,0, 0.75), rgba(0,0,0, 0.75)), url('/src/assets/img/teamwork.jpeg');
}


.pin-field {
	background-color: rgb(248, 249, 250);
	border: 1px solid rgb(204, 204, 204);
	border-radius: 0.3rem;
	font-size: 2rem;
	margin: 0.25rem;
	height: 3.5rem;
	outline: none;
	text-align: center;
	transition-duration: 250ms;
	transition-property: background, color, border, box-shadow, transform;
	width: 3rem;
  }
  
  .pin-field:focus {
	border-color: rgb(0, 123, 255);
	outline: none;
	transform: scale(1.05);
  }
  
  .pin-field:invalid {
	animation: shake 3 linear 75ms;
	border-color: rgb(220, 53, 69);
	box-shadow: 0 0 0.25rem rgba(220, 53, 69, 0.5);
  }
  
  swd-pin-field[completed] .pin-field {
	border-color: rgb(40, 167, 69);
	background-color: rgba(40, 167, 69, 0.25);
  }
  
  @keyframes shake {
	from {
	  transform: scale(1.05) translateY(-5%);
	}
	to {
	  transform: scale(1.05) translateY(5%);
	}
  }


	.pincode-input-text
	{
	  padding:0 !important;
	  margin:0 2px;
	  text-align:center;
	  border: 1px solid;
	  background: transparent;
	  width: 50px;
	  height: 50px;
	}
	.pincode-input-text:focus
	{
	  outline:none;
	  box-shadow:none;
	}
  