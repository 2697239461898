.w-200 {
  width: 200px;
}

.card-width {
  width: 40%;
  position: relative;
}

.flex-grow-3 {
  flex-grow: 1; /* default 0 */
}

.legend {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 2em;
}
.ml-10 {
  /* margin-left: 3.6em; */
  top: 0;
  right: 0;
}

.status_card {
  /* margin-left: 17em; */
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.icon-card {
  position: fixed;
  top: 0;
  right: 0;
}

.card-body {
  display: flex;
}

.title {
  padding-left: 1em;
  padding-top: 0.5em;
  color: darkgray;
}

.close-btn {
  position: absolute;
  right: 6px;
  top: 20px;
  cursor: pointer;
}

.card-title {
  display: flex;
  cursor: pointer;
}

.ml-50 {
  margin-left: 500em;
}
.ml-15 {
  margin-left: 2.3em;
}

.width-Card {
  width: 25%;
  position: relative;
}
.box {
  transform: rotate(90deg);
}

.rounded-xl button {
  background-color: #0d47a1;
}

.rounded-xl .bg-white\/30 {
  opacity: 0.3;
}

/* .rounded-xl .bg-white\/10 {
  display: none !important;
} */
.rounded-xl button :hover {
  background-color: #0d47a1;
}
/* .rounded-xl button .hover:bg-white\/10 {
  background-color: #0d47a1;

  opacity: 0.3;
} */

@media screen and (max-width: 1020px) {
  .ml-50 {
    margin-left: 0em;
  }
  .desck {
    width: 5%;
    height: 5%;
  }
  .card-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
  }
  .ml-15 {
    margin-left: 0em;
  }
  .ml-20 {
    margin-left: 0em;
  }
}

.path {
  cursor: pointer;
}
.center-legend {
  margin-left: 20%;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  color: red;
}
.plan {
  stroke: transparent;
  stroke-width: 0px;
}

.shadow_tooltip {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  /* filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7)); */
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
  /* Similar syntax to box-shadow */
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
