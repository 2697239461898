.w-200 {
  width: 200px;
}

.w-300 {
  width: 21em;
}

.template-wrap {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;

  /* height: 200px; */
}
.subject {
  height: 20px;
  padding-left: 20px;
  padding-right: 5px;
  font-size: 14px;
  font-weight: 500;
}
.e-schedule .e-appointment-details {
  padding: 0 !important;
}

.image {
  width: 40%;
  margin-left: 60px;
  margin-top: 15px;
}

.dx-scheduler-appointment {
  /* color: #000; */
  font-weight: 500;
  background-color: #e8eaed;
  border-color: #ebeeef;
  border-top: #ebeeef;
  border-radius: 0.5em;
  border: #e8eaed;
  box-shadow: 2px 2px 5px rgb(197, 197, 197);
}

.dx-scheduler-appointment.dx-state-hover {
  box-shadow: 5px 5px 5px rgb(197, 197, 197);
}
.dx-scheduler-appointment.dx-state-focused {
  /* box-shadow: inset 0 1px 0 0 rgb(0, 0, 0 / 30%); */
  border-radius: 0.5em;
  /* border: #e8eaed; */
  background-color: "#e8eaed";
  box-shadow: 5px 5px 5px rgb(197, 197, 197);
}
.dx-item-content .dx-scheduler-appointment-content {
  background-color: #e8eaed;
}
.dx-list-item {
}
.delete-section {
  position: absolute;
  right: 5px;
}

.dx-scheduler-appointment-recurrence .dx-scheduler-appointment-content {
  padding: 5px 0 5px 7px;
}

.options {
  /* background-color: rgba(191, 191, 191, 0.15); */
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
}

.column {
  width: 40%;
  display: inline-block;
  margin: 15px 3%;
  text-align: left;
  vertical-align: top;
}

.option {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.label,
.value {
  display: inline-block;
  vertical-align: middle;
}

.label {
  width: 180px;
}

.value {
  width: 30%;
}

.movie img {
  height: 70px;
}

.movie-text {
  font-size: 90%;
  white-space: normal;
}

#allow-shading,
#show-indicator {
  height: 36px;
}

.dx-list-item {
  pointer-events: none;
}

/* .dx-state-hover.dx-state-focused {
    background-color:red;
  }  */
/* .dx-state-hover {
    background-color: #e8eaed;
  }  */
/* .dx-list-item.dx-state-active {
    pointer-events: none;
    background-color: #e8eaed;
  }  */

/* customSchedulerStyles.css */
/* .dx-scheduler-work-space .dx-scheduler-date-table-cell {
  height: 100px;
} */
